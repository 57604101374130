import { useEffect, FC } from 'react'

import AppRoutes from 'app/AppRoutes'
import DocumentUploader from 'noauth/pages/DocumentUploader'
import LoginManager from 'noauth/pages/Login/LoginManager'
import { LoginLayout, LoginInfoProvider } from 'noauth/pages/Login/LoginWrapper'
import { AuthTypes } from 'noauth/pages/Login/LoginWrapper/LoginInfoProvider'
import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import { getStoredToken, decodeToken, isTokenValid } from 'core/actions/helpers/tokenHelpers'
import getLoanTypes from 'core/actions/loanTypes/getLoanTypes'
import getPermissions from 'core/actions/permissions/getPermissions'
import { useSetGlobalValue } from 'core/badGlobalDoNotUse'
import { getOverride } from 'core/components/PeachOverrides/storage'
import { useAppDispatch } from 'core/hooks/useAppStore'

import LoginRedirect from './loginRedirect/LoginRedirect'

type AppProps = {
  appDomainType: 'borrower' | 'agent' | 'admin'
  userType: 'borrower' | 'agent'
  companyId: string
  authValueType: 'email' | 'username' | 'phone' | 'samlSubjectNameId'
  authType: AuthTypes
  ssoUrl: string
  companyTheme: string
}

const App: FC<AppProps> & {
  dependencies: {
    useDispatch: typeof useDispatch
    useEffect: typeof useEffect
    isTokenValid: typeof isTokenValid
  }
} = ({ appDomainType, userType, companyId, authType, authValueType, ssoUrl, companyTheme }) => {
  const token = getStoredToken()
  const { personId: sessionPersonId } = decodeToken(token)

  const { useEffect, isTokenValid } = App.dependencies

  const dispatch = useAppDispatch()
  const tokenIsValid = isTokenValid(token)
  const forceUnhandledException = getOverride('forceUnhandledException')

  // this is a hack to allow reducers and other functions access to
  // current user's personId
  useSetGlobalValue('sessionPersonId', sessionPersonId)

  /**
   * Fetching all loan types here since it only needs to loaded once.
   */
  useEffect(() => {
    if (companyId && tokenIsValid) {
      dispatch(getLoanTypes({ companyId, key: 'GetLoanTypes' }))
      dispatch(getPermissions({ key: 'GetPermissions' }))
    }
  }, [companyId, dispatch, tokenIsValid])

  // istanbul ignore next
  if (forceUnhandledException) throw Error('forceUnhandledException')

  return (
    <LoginInfoProvider
      appDomainType={appDomainType}
      userType={userType}
      companyId={companyId}
      authType={authType}
      authValueType={authValueType}
      ssoUrl={ssoUrl}
    >
      <Switch>
        {/* routes where it doesn't matter if you're logged in */}
        <Route path='/login'>
          <LoginLayout companyTheme={companyTheme}>
            <LoginManager />
          </LoginLayout>
        </Route>

        {/*
      this route enables customers to link to our app and opt-into sending the user directly into the SSO flow.
      we don't do this for all /login routes to avoid getting the user into a redirect loop of
      in-app -> click sign-out -> redirect to /login -> redirect to SSO -> redirected back into app -> oops we're signed in again
      */}

        <Route path='/sso-login'>
          <Redirect to='/login?redirect=immediate' />
        </Route>

        <Route path='/document-uploader/:uploadDocumentLinkKey'>
          <DocumentUploader />
        </Route>

        <Route>
          {tokenIsValid ?
            <AppRoutes sessionPersonId={sessionPersonId} companyTheme={companyTheme} />
          : <LoginRedirect />}
        </Route>
      </Switch>
    </LoginInfoProvider>
  )
}

App.dependencies = {
  isTokenValid,
  useDispatch,
  useEffect,
}

export default App
