/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const unboundInteractionGetAll: OperationData<Types.UnboundInteractionGetAll> = {
  method: 'get',
  name: 'unboundInteractionGetAll',
  summary: `Get interactions`,
  path: '/interactions',
  queryKey: ({}) => ['interactions'],
}

export const unboundInteractionGetById: OperationData<Types.UnboundInteractionGetById> = {
  method: 'get',
  name: 'unboundInteractionGetById',
  summary: `Get interaction by ID`,
  path: '/interactions/{interactionId}',
  queryKey: ({ interactionId }) => ['interactions', interactionId],
}

export const unboundInteractionDelete: OperationData<Types.UnboundInteractionDelete> = {
  method: 'delete',
  name: 'unboundInteractionDelete',
  summary: `Delete unbound interaction`,
  path: '/interactions/{interactionId}',
  queryKey: ({ interactionId }) => ['interactions', interactionId],
}

export const unboundGetManyPreviousInteractions: OperationData<Types.UnboundGetManyPreviousInteractions> = {
  method: 'get',
  name: 'unboundGetManyPreviousInteractions',
  summary: `Get previous interactions`,
  path: '/interactions/{interactionId}/previous',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'previous'],
}

export const interactionConvoGet: OperationData<Types.InteractionConvoGet> = {
  method: 'get',
  name: 'interactionConvoGet',
  summary: `Get interaction convo`,
  path: '/interactions/{interactionId}/convo',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo'],
}

export const interactionConvoJoin: OperationData<Types.InteractionConvoJoin> = {
  method: 'post',
  name: 'interactionConvoJoin',
  summary: `Join interaction convo`,
  path: '/interactions/{interactionId}/convo/join',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'join'],
}

export const interactionConvoLeave: OperationData<Types.InteractionConvoLeave> = {
  method: 'post',
  name: 'interactionConvoLeave',
  summary: `Leave interaction convo`,
  path: '/interactions/{interactionId}/convo/leave',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'leave'],
}

export const interactionConvoClose: OperationData<Types.InteractionConvoClose> = {
  method: 'post',
  name: 'interactionConvoClose',
  summary: `Close interaction convo`,
  path: '/interactions/{interactionId}/convo/close',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'convo', 'close'],
}

export const unboundInteractionDownloadRecording: OperationData<Types.UnboundInteractionDownloadRecording> = {
  method: 'get',
  name: 'unboundInteractionDownloadRecording',
  summary: `Download interaction recording`,
  path: '/interactions/{interactionId}/recording-content',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'recording-content'],
}

export const unboundInteractionDownloadVoicemail: OperationData<Types.UnboundInteractionDownloadVoicemail> = {
  method: 'get',
  name: 'unboundInteractionDownloadVoicemail',
  summary: `Download interaction voicemail`,
  path: '/interactions/{interactionId}/voicemail-content',
  queryKey: ({ interactionId }) => ['interactions', interactionId, 'voicemail-content'],
}

export const interactionGetAll: OperationData<Types.InteractionGetAll> = {
  method: 'get',
  name: 'interactionGetAll',
  summary: `Get borrower interactions`,
  path: '/people/{personId}/interactions',
  queryKey: ({ personId }) => ['people', personId, 'interactions'],
}

export const interactionAdd: OperationData<Types.InteractionAdd> = {
  method: 'post',
  name: 'interactionAdd',
  summary: `Create interaction`,
  path: '/people/{personId}/interactions',
  queryKey: ({ personId }) => ['people', personId, 'interactions'],
}

export const interactionGetById: OperationData<Types.InteractionGetById> = {
  method: 'get',
  name: 'interactionGetById',
  summary: `Get interaction by ID`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const interactionUpdate: OperationData<Types.InteractionUpdate> = {
  method: 'put',
  name: 'interactionUpdate',
  summary: `Update interaction`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const interactionDelete: OperationData<Types.InteractionDelete> = {
  method: 'delete',
  name: 'interactionDelete',
  summary: `Delete interaction`,
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId],
}

export const getManyPreviousInteractions: OperationData<Types.GetManyPreviousInteractions> = {
  method: 'get',
  name: 'getManyPreviousInteractions',
  summary: `Get previous interactions`,
  path: '/people/{personId}/interactions/{interactionId}/previous',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'previous'],
}

export const interactionDownloadRecording: OperationData<Types.InteractionDownloadRecording> = {
  method: 'get',
  name: 'interactionDownloadRecording',
  summary: `Download interaction recording`,
  path: '/people/{personId}/interactions/{interactionId}/recording-content',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'recording-content'],
}

export const interactionDownloadVoicemail: OperationData<Types.InteractionDownloadVoicemail> = {
  method: 'get',
  name: 'interactionDownloadVoicemail',
  summary: `Download interaction voicemail`,
  path: '/people/{personId}/interactions/{interactionId}/voicemail-content',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'voicemail-content'],
}

export const getInteractionCases: OperationData<Types.GetInteractionCases> = {
  method: 'get',
  name: 'getInteractionCases',
  summary: `Get cases associated with an interaction`,
  path: '/people/{personId}/interactions/{interactionId}/cases',
  queryKey: ({ personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'cases'],
}

export const interactionSearch: OperationData<Types.InteractionSearch> = {
  method: 'get',
  name: 'interactionSearch',
  summary: `Search a borrower's interactions`,
  path: '/people/{personId}/interactions/search',
  queryKey: ({ personId }) => ['people', personId, 'interactions', 'search'],
}

export const internalUnboundInteractionGetAll: OperationData<Types.InternalUnboundInteractionGetAll> = {
  method: 'get',
  name: 'internalUnboundInteractionGetAll',
  summary: `Get interactions`,
  path: '/companies/{companyId}/interactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'interactions'],
}

export const unboundInteractionAdd: OperationData<Types.UnboundInteractionAdd> = {
  method: 'post',
  name: 'unboundInteractionAdd',
  summary: `Create unbound interaction`,
  path: '/companies/{companyId}/interactions',
  queryKey: ({ companyId }) => ['companies', companyId, 'interactions'],
}

export const internalUnboundInteractionGetById: OperationData<Types.InternalUnboundInteractionGetById> = {
  method: 'get',
  name: 'internalUnboundInteractionGetById',
  summary: `Get unbound interaction by ID`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const unboundInteractionUpdate: OperationData<Types.UnboundInteractionUpdate> = {
  method: 'put',
  name: 'unboundInteractionUpdate',
  summary: `Update unbound interaction`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const internalUnboundInteractionDelete: OperationData<Types.InternalUnboundInteractionDelete> = {
  method: 'delete',
  name: 'internalUnboundInteractionDelete',
  summary: `Delete unbound interaction`,
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId],
}

export const internalUnboundGetManyPreviousInteractions: OperationData<Types.InternalUnboundGetManyPreviousInteractions> =
  {
    method: 'get',
    name: 'internalUnboundGetManyPreviousInteractions',
    summary: `Get previous interactions`,
    path: '/companies/{companyId}/interactions/{interactionId}/previous',
    queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'previous'],
  }

export const internalInteractionConvoGet: OperationData<Types.InternalInteractionConvoGet> = {
  method: 'get',
  name: 'internalInteractionConvoGet',
  summary: `Get interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo'],
}

export const internalInteractionConvoJoin: OperationData<Types.InternalInteractionConvoJoin> = {
  method: 'post',
  name: 'internalInteractionConvoJoin',
  summary: `Join interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/join',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'join'],
}

export const internalInteractionConvoLeave: OperationData<Types.InternalInteractionConvoLeave> = {
  method: 'post',
  name: 'internalInteractionConvoLeave',
  summary: `Leave interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/leave',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'leave'],
}

export const internalInteractionConvoClose: OperationData<Types.InternalInteractionConvoClose> = {
  method: 'post',
  name: 'internalInteractionConvoClose',
  summary: `Close interaction convo`,
  path: '/companies/{companyId}/interactions/{interactionId}/convo/close',
  queryKey: ({ companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, 'convo', 'close'],
}

export const internalUnboundInteractionDownloadRecording: OperationData<Types.InternalUnboundInteractionDownloadRecording> =
  {
    method: 'get',
    name: 'internalUnboundInteractionDownloadRecording',
    summary: `Download interaction recording`,
    path: '/companies/{companyId}/interactions/{interactionId}/recording-content',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'recording-content',
    ],
  }

export const internalUnboundInteractionDownloadVoicemail: OperationData<Types.InternalUnboundInteractionDownloadVoicemail> =
  {
    method: 'get',
    name: 'internalUnboundInteractionDownloadVoicemail',
    summary: `Download interaction voicemail`,
    path: '/companies/{companyId}/interactions/{interactionId}/voicemail-content',
    queryKey: ({ companyId, interactionId }) => [
      'companies',
      companyId,
      'interactions',
      interactionId,
      'voicemail-content',
    ],
  }
