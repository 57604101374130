// Third party --------------
import React from 'react'

import styled, { css } from 'styled-components'

import { CompanyVisibility } from 'core/components/legacy'

// Components ---------------
import PoweredByPeach from 'core/components/lib/PoweredByPeach'
import { useRect, useCompanyInfo, useUserType } from 'core/hooks'
import variables from 'core/styles/variables'

import FooterCompanyInfo from './FooterCompanyInfo'
import FooterConsents from './FooterConsents'
import FooterDisclosures from './FooterDisclosures'
import FooterLinks from './FooterLinks'

// Rest ---------------------

const Wrapper = styled.div``

const MaxWidth = styled.div`
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
`

const FooterTop = styled.div`
  text-align: center;
  background-color: ${variables.colorBlack20};
`

const threeColumnsStyles = css`
  display: flex;
  flex-direction: row;

  & > * {
    flex-basis: 10px;
    flex-grow: 1;
  }
`

const FooterTopContent = styled.div`
  max-width: 1186px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;

  ${(p) => !p.isMobile && threeColumnsStyles};
`

const Section = styled.div`
  padding: 24px;
`

const FooterBottom = styled.div`
  padding-top: 42px;
  text-align: center;
`

const FooterBottomContent = styled.div`
  padding-right: 16px;
  padding-left: 16px;
`

const PoweredByArea = styled.div`
  padding: 8px 24px;
  text-align: center;
`

const Footer = () => {
  const [{ width }, ref] = useRect()

  const { showPoweredByPeach } = useCompanyInfo()
  const userType = useUserType()
  const isBorrower = userType === 'borrower'

  const isMobile = width < 1024

  return (
    <Wrapper>
      <CompanyVisibility uiKey='mobileFooter'>
        <FooterTop ref={ref}>
          <MaxWidth>
            <FooterTopContent isMobile={isMobile}>
              <Section>
                <FooterLinks />
              </Section>
              <Section>
                <FooterCompanyInfo centerLogo={isMobile} />
              </Section>
              <Section>
                <FooterConsents />
              </Section>
            </FooterTopContent>
          </MaxWidth>
        </FooterTop>
      </CompanyVisibility>

      <FooterBottom>
        <MaxWidth>
          <FooterBottomContent>
            <CompanyVisibility uiKey='mobileDisclosures'>
              <FooterDisclosures />
            </CompanyVisibility>

            {showPoweredByPeach && !isBorrower && (
              <PoweredByArea>
                <PoweredByPeach />
              </PoweredByArea>
            )}
          </FooterBottomContent>
        </MaxWidth>
      </FooterBottom>
    </Wrapper>
  )
}

export default Footer
