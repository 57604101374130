import { ReactNode, useEffect } from 'react'

import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import ReactSelect, { GroupBase, MultiValue, OnChangeValue, Props as ReactSelectProps, SingleValue } from 'react-select'
import * as R from 'remeda'
import styled from 'styled-components'

import customStyles from 'core/components/legacy/Select/customStyles'
import { PendingEventHandler, usePendingCallback } from 'core/components/lib/Button/Button'

import { DropdownIndicator, MenuList, Option, OptionsOrGroups, SelectOption, ValueContainer } from './components'

export const findByValue = <T,>(options: OptionsOrGroups<T> | undefined, value: T) =>
  R.pipe(
    options ?? [],
    R.flatMap((o) => ('options' in o ? o.options : o)),
    R.find((o) => R.equals(o.value, value)),
  )

type SelectInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean,
  Value,
> = Omit<UseControllerProps<TFieldValues, TName>, 'disabled'> &
  Omit<
    ReactSelectProps<SelectOption<Value>, IsMulti, GroupBase<SelectOption<Value>>>,
    'disabled' | 'onChange' | 'onBlur' | 'value' | 'name' | 'styles' | 'defaultValue'
  > & {
    className?: string
    width?: string
    onChange?: PendingEventHandler<OnChangeValue<SelectOption<Value>, IsMulti>>
    unwrapValue?: boolean
    prefix?: ReactNode
  }

const SelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean = false,
  Value = string,
>({
  className,
  width,
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  options,
  isMulti,
  isDisabled,
  isLoading,
  onChange: passedOnChange,
  ...reactSelectProps
}: SelectInputProps<TFieldValues, TName, IsMulti, Value>) => {
  const {
    formState,
    field: { onChange: controlOnChange, value, disabled, ...field },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    disabled: isDisabled,
  })

  const selected =
    !value ? value
    : isMulti ?
      R.pipe(
        value as Array<Value>,
        R.map((v) => findByValue(options, v)),
        R.compact,
      )
    : findByValue<Value>(options, value)

  useEffect(() => {
    if (!isLoading && value && selected === undefined) {
      controlOnChange(formState.defaultValues?.[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const checkMulti = (
    option: MultiValue<SelectOption<Value>> | SingleValue<SelectOption<Value>>,
  ): option is MultiValue<SelectOption<Value>> => !!isMulti

  const rawOnChange: typeof passedOnChange = (option) => {
    controlOnChange(
      !option ? option
      : checkMulti(option) ? R.map(option, R.prop('value'))
      : option.value,
    )
    return passedOnChange?.(option)
  }
  const [isPending, onChange] = usePendingCallback(rawOnChange)

  // This needs to be passed as a prop so we can share styles with the existing Select component.
  // I'm not exposing it as a prop because I don't believe it should be optional as these fields
  // quickly become unusable if the selections do not wrap onto newlines.
  const additionalProps = { wrap: true }

  return (
    <Box className={className} width={width}>
      <ReactSelect
        components={{
          DropdownIndicator,
          MenuList,
          Option,
          ValueContainer,
        }}
        styles={customStyles}
        options={options}
        isDisabled={isPending || isLoading || disabled || formState.isSubmitting}
        onChange={onChange}
        menuPlacement='auto'
        isLoading={isPending || isLoading}
        value={selected} // Workaround for JedWatson/react-select#5729
        {...reactSelectProps}
        {...field}
        {...additionalProps}
        {...(isMulti && { isMulti })}
      />
    </Box>
  )
}

export default SelectInput

const Box = styled.div<{ width?: string }>`
  width: ${(p) => p.width ?? 'initial'};
`
